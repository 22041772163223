import { Suspense } from "react";
import Spinner from "../../../../pages/spinner/Spinner";

// ===========================|| LOADABLE - LAZY LOADING ||=========================== //

const Loadable = (Component) => (props) =>
    (
        <Suspense fallback={<Spinner />}>
            <Component {...props} />
        </Suspense>
    );

export default Loadable;