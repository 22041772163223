import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    name: 'Jasa Tukang',
    phone: '+62 812 1826 7480',
    email: 'jasatukang@gmail.com',
  };

export const CompanySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        setName: (state, action) => {
            state.name = action.payload;
        },
        setPhone: (state, action) => {
            state.phone = action.payload;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
        },
    },
});

export const {
    setName,
    setPhone,
    setEmail,
} = CompanySlice.actions;

export default CompanySlice.reducer;