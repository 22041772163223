import { Link } from "react-router-dom";

import '../../assets/css/bootstrap.min.css';
import '../../assets/css/style.css';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Container from "../bootstrap/Container";

const Navbar = ( { title, menus } ) => {

    console.log('top level title ', title);

    const [ navClass, setNavClass ] = useState('fixed-top px-0 wow fadeIn');
    // const [ navCss, setNavCss ] = useState({});

    const handleScroll = () => {
        // if(window.innerWidth < 992) {
            if(window.scrollY > 45) {
                setNavClass('fixed-top px-0 wow fadeIn bg-black shadow');
                // setNavCss({});
            }
            else {
                setNavClass('fixed-top px-0 wow fadeIn');
                // setNavCss({});
            }
        // } else {
        //     if(window.scrollY > 45) {
        //         setNavClass('fixed-top px-0 wow fadeIn bg-dark shadow');
        //         // setNavCss({top: -45});
        //     }
        //     else {
        //         setNavClass('fixed-top px-0 wow fadeIn');
        //         // setNavCss({top: 0});
        //     }
        // }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [navClass]);

    const company = useSelector((state) => state.company);

    return(
        // <div className={navClass} data-wow-delay="0.1s" style={navCss}>
        <Container className={navClass} type="fluid" data-wow-delay="0.1s">
            {/* <div className="top-bar text-white-50 row gx-0 align-items-center d-none d-lg-flex">
                <div className="col-lg-6 px-5 text-start">
                    <small><i className="fa fa-map-marker-alt me-2"></i>Rawalumbu, Bekasi, Indonesia</small>
                    <small className="ms-4"><i className="fa fa-envelope me-2"></i>{company.email}</small>
                </div>
                <div className="col-lg-6 px-5 text-end">
                    <small>Follow us:</small>
                    <Link className="text-white-50 ms-3" to=""><i className="fab fa-facebook-f"></i></Link>
                    <Link className="text-white-50 ms-3" to=""><i className="fab fa-twitter"></i></Link>
                    <Link className="text-white-50 ms-3" to=""><i className="fab fa-linkedin-in"></i></Link>
                    <Link className="text-white-50 ms-3" to=""><i className="fab fa-instagram"></i></Link>
                </div>
            </div> */}

            <nav className="navbar navbar-expand-lg navbar-dark py-lg-0 px-lg-5 wow fadeIn" data-wow-delay="0.1s">
                <Link to="/" className="navbar-brand ms-4 ms-lg-0">
                        {
                            <h4 className="fw-bold text-primary m-0">
                                {title.split(' ')[0]}
                                <span className="text-white">{title.split(' ')[1]}</span>
                            </h4>   
                        }
                </Link>
                <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto p-4 p-lg-0">
                        {menus?.map((menu) => {
                            if(menu.type === 'link') return <Link to={menu.link} className="nav-item nav-link">{menu.title}</Link>
                            else return (
                                <div className="nav-item dropdown">
                                    <Link to='#' className="nav-link dropdown-toggle" data-bs-toggle='dropdown'>{menu.title}</Link>
                                    <div className="dropdown-menu m-0">
                                        {menu?.children?.map((cmenu) => {
                                           return <Link to={cmenu.link} className="dropdown-item">{cmenu.title}</Link>
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {/* <div className="d-none d-lg-flex ms-2">
                        <Link to='' className="btn btn-outline-primary py-2 px-3">
                            Donate Now
                            <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                <i className="fa fa-arrow-right"></i>
                            </div>
                        </Link>
                    </div> */}
                </div>
            </nav>
        {/* </div> */}
        </Container>
    );
};

export default Navbar;