import { useSelector } from "react-redux";
import { DarkThemeColors } from "./DarkThemeColors";
import { LightThemeColors } from "./LightThemeColors"
import { baseDarkTheme, baselightTheme } from "./DefaultColors";
import { darkshadows, shadows } from "./Shadows";
import typography from "./Typography";
import { createTheme } from "@mui/material/styles";
import components from "./Components";
import { useEffect } from "react";
import _ from "lodash";

export const BuildTheme = (config = {}) => {
    const themeOptions = LightThemeColors.find((theme) => theme.name === config.theme);
    const darkthemeOptions = DarkThemeColors.find((theme) => theme.name === config.theme);
    const customizer = useSelector((state) => state.customizer);
    const defaultTheme = customizer.activeMode === 'dark' ? baseDarkTheme : baselightTheme;
    const defaultShadow = customizer.activeMode === 'dark' ? darkshadows : shadows;
    const themeSelect = customizer.activeMode === 'dark' ? darkthemeOptions : themeOptions;

    const baseMode = {
        palette: {
            mode: customizer.activeMode,
        },
        shape: {
            borderRadius: customizer.borderRadius,
        },
        shadows: defaultShadow,
        typography: typography,
    };
    const theme = createTheme(
        // _.merge({}, baseMode, defaultTheme, locales[customizer.isLanguage], themeSelect, {
        _.merge({}, baseMode, defaultTheme, null, themeSelect, {
            direction: config.direction,
        }),
    );
    theme.components = components(theme);

    return theme;
};

const ThemeSettings = () => {
    const activDir = useSelector((state) => state.customizer.activDir);
    const activeTheme = useSelector((state) => state.customizer.activeTheme);
    const theme = BuildTheme({
        direction: activDir,
        theme: activeTheme,
    });
    useEffect(() => {
        document.dir = activDir;
    }, [activDir]);

    return theme;
};

export { ThemeSettings };