import '../assets/css/style.css';
import '../assets/css/bootstrap.min.css';

import Footer from "../components/shared/Footer";
import Header from "../components/shared/Header";

const DonateBody = () => {
    return (
        <div className="container-fluid py-5">
            <div className="container">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                        <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">Donate Now</div>
                        <h1 className="display-6 mb-5">Thanks For The Results Achieved With You</h1>
                        <p className="mb-0">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo magna dolore erat amet</p>
                    </div>
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                        <div className="h-100 bg-secondary p-5">
                            <form>
                                <div className="row g-3">
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <input type="text" className="form-control bg-light border-0" id="name" placeholder="Your Name" />
                                            <label for="name">Your Name</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <input type="email" className="form-control bg-light border-0" id="email" placeholder="Your Email" />
                                            <label for="email">Your Email</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="btn-group d-flex justify-content-around">
                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio1" checked />
                                            <label className="btn btn-light py-3" for="btnradio1">$10</label>

                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio2" />
                                            <label className="btn btn-light py-3" for="btnradio2">$20</label>

                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio3" />
                                            <label className="btn btn-light py-3" for="btnradio3">$30</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        {/* <button className="btn btn-primary px-5" style="height: 60px;"> */}
                                        <button className="btn btn-primary px-5" style={{height: '60px'}}>
                                            Donate Now
                                            <div className="d-inline-flex btn-sm-square bg-white text-primary rounded-circle ms-2">
                                                <i className="fa fa-arrow-right"></i>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Donate = () => {
    return (
        <>
            <Header title='Donate' />
            <DonateBody />
        </>
    )
}

export default Donate;