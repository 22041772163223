const Container = ( { type, className, children, style } ) => {
    return (
        <div
            className={className? (type? "container-" + type + " " : "container ") + className : (type? "container-" + type + " " : "container")}
            style={style? style : {}}
        >
            {children}
        </div>
    )
};

export default Container;