import '../../assets/css/style.css';
import '../../assets/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Container from '../bootstrap/Container';

const Footer = () => {

    const company = useSelector((state) => state.company);

    return (
        // <div className="container-fluid bg-dark text-white-50 footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
        <Container type="fluid" className="bg-dark text-white-50 footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-lg-3 col-md-6">
                        {
                            <h1 className="fw-bold text-primary mb-4">
                                {company.name.split(' ')[0]}
                                <span className="text-white">{company.name.split(' ')[1]}</span>
                            </h1>
                        }
                        <p>Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet lorem sit clita</p>
                        <div className="d-flex pt-2">
                            <Link className="btn btn-square me-1" to=""><i className="fab fa-twitter"></i></Link>
                            <Link className="btn btn-square me-1" to=""><i className="fab fa-facebook-f"></i></Link>
                            <Link className="btn btn-square me-1" to=""><i className="fab fa-youtube"></i></Link>
                            <Link className="btn btn-square me-0" to=""><i className="fab fa-linkedin-in"></i></Link>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h5 className="text-light mb-4">Alamat</h5>
                        <p><i className="fa fa-map-marker-alt me-3"></i>Rawalumbu, Bekasi</p>
                        <p><i className="fa fa-phone-alt me-3"></i>{company.phone}</p>
                        <p><i className="fa fa-envelope me-3"></i>{company.email}</p>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h5 className="text-light mb-4">Links</h5>
                        <Link to='' className="btn btn-link">About Us</Link>
                        <Link to='' className="btn btn-link">Contact Us</Link>
                        <Link to='' className="btn btn-link">Our Services</Link>
                        <Link to='' className="btn btn-link">Terms & Condition</Link>
                        <Link to='' className="btn btn-link">Support</Link>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h5 className="text-light mb-4">Berita</h5>
                        <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
                        {/* <div className="position-relative mx-auto" style="max-width: 400px;"> */}
                        <div className="position-relative mx-auto" style={{maxWidth: '400px',}}>
                            <input className="form-control bg-transparent w-100 py-3 ps-4 pe-5" type="text" placeholder="Email anda" />
                            <button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">Daftar</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                            &copy; <Link to="#">Jasa Tukang</Link>, All Right Reserved.
                        </div>
                        <div className="col-md-6 text-center text-md-end">
                        </div>
                    </div>
                </div>
            </div>
        {/* </div> */}
        </Container>
    );
}

export default Footer;