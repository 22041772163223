import { useRoutes } from "react-router-dom"
import Router from "./Router"
import RTL from "./layouts/full/shared/customizer/RTL";
import { useSelector } from "react-redux";
import ScrollToTop from "./components/shared/ScrollToTop";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ThemeSettings } from "./theme/Theme";
import { useEffect } from "react";

const App = () => {
    const routing = useRoutes(Router);
    // const theme = ThemeSettings();
    const customizer = useSelector((state) => state.customizer);
    
    return (
        // <ThemeProvider theme={theme}>    
            <RTL direction={customizer.activeDir}>
                <CssBaseline />
                <ScrollToTop>{routing}</ScrollToTop>
            </RTL>
        // </ThemeProvider>
    );
};

export default App;