import { lazy } from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import Loadable from './layouts/full/shared/loadable/Loadable';
import Navbar from './components/shared/Navbar';
import Donate from './pages/Donate';
import Causes from './pages/Causes';

/** Layouts */
const FullLayout = Loadable(lazy(() => import('./layouts/full/FullLayout')));

/** Pages */
const Home = Loadable(lazy(() => import('./pages/Home')));
const Contact = Loadable(lazy(() => import('./pages/Contact')));
const Portofolio = Loadable(lazy(() => import('./pages/Portofolio')));
const Service = Loadable(lazy(() => import('./pages/Service')));
const Team = Loadable(lazy(() => import('./pages/Team')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));

const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <Navigate to='/home' /> },
      { path: '/home', element: <Home /> },
      { path: '/causes', element: <Causes /> },
      { path: '/contact', element: <Contact /> },
      { path: '/portofolio', element: <Portofolio /> },
      { path: '/team', element: <Team /> },

      { path: '/service', element: <Service /> },
      { path: '/donate', element: <Donate /> },
      { path: '*', element: <NotFound /> },
    ],
  },
];

export default Router;
